  <button [attr.aria-label]="label" class="btn" [ngClass]="buttonclass" (click)="buttonClick()" [disabled]="disabled">
    <span *ngIf="icon"><i class="bi bi-swe-fw {{icon}}" [ngClass]="{'bi-swe-spin': loading}"></i></span>
    <div  *ngIf="!icon||showLabel" [ngClass]="{'d-inline': showLabel}">
      <span [ngClass]="{'d-none d-md-inline': collapsed,'d-inline': !collapsed}">{{label}}&nbsp;</span>
      <span><i class="bi bi-caret-down-fill"></i></span>
    </div>
  </button>
  <ng-content>

  </ng-content>
  <div class="swe-element-clickable-area swe-element-clickable-area-md" *ngIf="isOpen" (click)="close()"></div>
