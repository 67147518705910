<swe-pageheader [header]="languageService.getItem(574)"></swe-pageheader>
<swe-fixedheader [more]="false" [listutility]="listutility" [listoptions]="listoptions">
  <ng-template #listoptions>
    <button [attr.aria-label]="languageService.getItem(843)" class="btn btn-swe" (click)="actionmenu=!actionmenu" [attr.disabled]="listutility.ischecked ? null : true">
      <span><i class="bi bi-swe-fw bi-three-dots-vertical"></i></span>
    </button>
    <ul class="dropdown-menu" [ngClass]="{'show': actionmenu && listutility.ischecked}">
      <li class="dropdown-item swe-click" *ngIf="permissionService.permissions.MultiLevel>0"><a (click)="action('multi')"><i class="bi bi-swe-fw bi-copy"></i>&nbsp;{{languageService.getItem(655)}}</a></li>
    </ul>
  </ng-template>
</swe-fixedheader>
<div class="row py-2">
  <div class="col-12">
    <swe-card [open]="true" [hasCollapse]="false" [hasRefresh]="true" (refreshChange)="load()">
      <span swe-rightcommand *ngIf="permissionService.permissions.Overtime>1">
        <i class="bi bi-swe-fw bi-plus-lg bi-swe-pull-right swe-click" (click)="goto(0, $event)"></i>
      </span>
      <swe-list [data]="ruleList" [loading]="isloading">
        <div *ngFor="let groupby of rules | keyvalue : originalOrder">
          <a class="list-group-item list-group-item-action d-flex flex-wrap bg-light">
            <h2>{{groupby.value.header}}<span class="ms-2" *ngIf="groupby.value.items.length>0">(#{{groupby.value.items[0].GroupId}})</span></h2>
          </a>
          <ng-container *ngFor="let rule of groupby.value.items">
            <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" [ngClass]="{'list-group-item-warning swe-scroll-to': rule.Id==settingService.lastobrule}" (click)="permissionService.permissions.Overtime>1 && goto(rule.Id, $event)">
              <div class="col-3 col-md-1" (click)="listutility.toggle(rule, $event)" *ngIf="permissionService.permissions.MultiObRules > 0">
                <span class="bi-swe-stack bi-swe-letter-circle-small" *ngIf="rule.checked">
                  <i class="bi bi-check-circle-fill bi-swe-stack-2x"></i>
                </span>
                <span class="bi-swe-stack bi-swe-letter-circle-small" *ngIf="!rule.checked">
                  <i class="bi bi-gear-fill bi-swe-stack-2x"></i>
                </span>
              </div>
              <div class="col-9 col-md-7">{{rule.Desc}}</div>
              <div class="col-4 col-md-2">{{rule.Type}}</div>
              <div class="col-6 col-md-2">
                <div *ngIf="rule.Level">{{rule.Level}}</div>
                <ng-container *ngIf="rule.Contracts.length>0">
                  <div [attr.title]="getToolTip(rule)">{{rule.Contracts[0]}}<span *ngIf="rule.Contracts.length>1">...</span></div>
                </ng-container>
              </div>
            </a>
          </ng-container>
        </div>
      </swe-list>
    </swe-card>
  </div>
</div>
