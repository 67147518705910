<nav class="navbar navbar-dark" *ngIf="authenticationService.isAuth">
  <div class="container-fluid">
    <button type="button" class="navbar-toggler mb-3" (click)="menuService.toggleMenu()" *ngIf="authenticationService.isAuth" #sweFocusElement>
      <span><i class="bi bi-list bi-swe-topmenu"></i></span>
      <span class="sr-only">Meny</span>
    </button>
    <a href="/" class="navbar-brand me-0" tabindex="-1"><img alt="SwedeTime" src="assets/images/swedetime2.png" /></a>
    <div class="navbar-collapse" *ngIf="permission">
      <ul class="navbar-nav">
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" routerLink="/" *ngIf="permissionService.permissions&&permissionService.permissions.Dashboard>0"><i class="bi bi-house-door-fill"></i>&nbsp;{{languageService.getItem(61)}}</a></li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" routerLink="/users" *ngIf="permissionService.permissions&&permissionService.permissions.Users>0"><i class="bi bi-people-fill"></i>&nbsp;{{languageService.getItem(2)}}</a></li> 
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" routerLink="/usermarkeddates" *ngIf="permissionService.permissions&&permissionService.permissions.MarkedDateMenu"><i class="bi bi-calendar-check"></i>&nbsp;{{languageService.getItem(51)}}</a></li> 
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" routerLink="/bookings" *ngIf="permissionService.permissions&&permissionService.permissions.Bookings>0"><i class="bi bi-list-ul"></i>&nbsp;{{languageService.getItem(4)}}</a></li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" routerLink="/timeline" *ngIf="permissionService.permissions&&permissionService.permissions.Timeline>0"><i class="bi bi-calendar-date"></i>&nbsp;{{languageService.getItem(273)}}</a></li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" routerLink="/timereports" *ngIf="permissionService.permissions&&permissionService.permissions.Timereports>0"><i class="bi bi-clock-fill"></i>&nbsp;{{languageService.getItem(52)}}</a></li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" routerLink="/levels" *ngIf="permissionService.permissions&&permissionService.permissions.Levels>0"><i class="bi bi-diagram-3-fill"></i>&nbsp;{{languageService.getItem(3)}}</a></li>
        <ng-container *ngFor="let category of categories">
          <li class="nav-item" *ngIf="permissionService.permissions && permissionService.hasObject(category.Key)>0"><a class="nav-link swe-nav-link" (click)="manageMenu()" routerLink="/objects/{{category.Key}}"><i class="bi bi-bookmark-fill"></i>&nbsp;{{category.Value}}</a></li>
        </ng-container>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" routerLink="/reports" *ngIf="permissionService.permissions&&permissionService.permissions.Reports>0"><i class="bi bi-bar-chart-fill"></i>&nbsp;{{languageService.getItem(289)}}</a></li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" routerLink="/messages" *ngIf="permissionService.permissions&&permissionService.permissions.Messages>0"><i class="bi bi-envelope-fill"></i>&nbsp;{{languageService.getItem(345)}}</a></li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" routerLink="/logs" *ngIf="permissionService.permissions&&permissionService.permissions.Logs>0"><i class="bi bi-clock-history"></i>&nbsp;{{languageService.getItem(400)}}</a></li>
      </ul>
    </div>
    <div class="navbar-collapse mt-5" *ngIf="adminpermission">
      <ul class="navbar-nav">
        <li class="navbar-text">Admin</li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" [routerLink]="'/admin/impersonate'" *ngIf="permissionService.permissions&&permissionService.permissions.Impersonate || permissionService.isimpersonate() > 0"><i class="bi bi-incognito"></i>&nbsp;{{languageService.getItem(317)}}</a></li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" [routerLink]="'/admin/news'" *ngIf="permissionService.permissions && permissionService.permissions.News > 1"><i class="bi bi-newspaper"></i>&nbsp;{{languageService.getItem(134)}}</a></li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" [routerLink]="'/admin/import'" *ngIf="permissionService.permissions && permissionService.permissions.Import > 0"><i class="bi bi-cloud-download"></i>&nbsp;{{languageService.getItem(636)}}</a></li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" [routerLink]="'/admin/reportarchive'" *ngIf="permissionService.permissions && permissionService.permissions.ReportArchive > 0"><i class="bi bi-archive-fill"></i>&nbsp;{{languageService.getItem(1073)}}</a></li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" [routerLink]="'/admin/properties'" *ngIf="permissionService.permissions && permissionService.permissions.Properties > 0"><i class="bi bi-list-check"></i>&nbsp;{{languageService.getItem(156)}}</a></li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" [routerLink]="'/admin/holidays'" *ngIf="permissionService.permissions && permissionService.permissions.Holiday > 0"><i class="bi bi-calendar3"></i>&nbsp;{{languageService.getItem(712)}}</a></li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" [routerLink]="'/admin/overtime'" *ngIf="permissionService.permissions && permissionService.permissions.Overtime > 0"><i class="bi bi-gear-fill"></i>&nbsp;{{languageService.getItem(574)}}</a></li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" [routerLink]="'/admin/contracts'" *ngIf="permissionService.permissions && permissionService.permissions.Contract > 0"><i class="bi bi-file-earmark-text-fill"></i>&nbsp;{{languageService.getItem(953)}}</a></li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" [routerLink]="'/admin/employments'" *ngIf="permissionService.permissions && permissionService.permissions.Contract > 0"><i class="bi bi-people-fill"></i>&nbsp;{{languageService.getItem(905)}}</a></li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" [routerLink]="'/admin/articles'" *ngIf="permissionService.permissions && permissionService.permissions.Articles > 0"><i class="bi bi-bank"></i>&nbsp;{{languageService.getItem(1234)}}</a></li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" [routerLink]="'/admin/integrations'" *ngIf="permissionService.permissions && permissionService.permissions.Integration > 0"><i class="bi bi-arrow-left-right"></i>&nbsp;{{languageService.getItem(877)}}</a></li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" [routerLink]="'/admin/externalproviders'" *ngIf="permissionService.permissions && permissionService.permissions.Integration > 0"><i class="bi bi-arrow-left-right"></i>&nbsp;{{languageService.getItem(1442)}}</a></li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" [routerLink]="'/admin/templates'" *ngIf="permissionService.permissions && permissionService.permissions.Templates > 0"><i class="bi bi-graph-up"></i>&nbsp;{{languageService.getItem(1009)}}</a></li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" [routerLink]="'/admin/economy'" *ngIf="permissionService.permissions && permissionService.permissions.Economy > 0"><i class="bi bi-cash-coin"></i>&nbsp;{{languageService.getItem(1312)}}</a></li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" [routerLink]="'/admin/guides'" *ngIf="permissionService.permissions && permissionService.permissions.GuideAccess > 0"><i class="bi bi-info-circle"></i>&nbsp;{{languageService.getItem(1390)}}</a></li>
      </ul>
    </div>
    <div class="navbar-collapse mt-5" *ngIf="permissionService.permissions && permissionService.user.IsSuper">
      <ul class="navbar-nav">
        <li class="navbar-text">SystemAdmin</li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" [routerLink]="'/systemadmin/settings'"><i class="bi bi-wrench-adjustable"></i>&nbsp;{{languageService.getItem(113)}}</a></li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" [routerLink]="'/systemadmin/tables'"><i class="bi bi-table"></i>&nbsp;{{languageService.getItem(876)}}</a></li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" [routerLink]="'/systemadmin/logsettings'"><i class="bi bi-clock-history"></i>&nbsp;{{languageService.getItem(400)}}</a></li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" [routerLink]="'/systemadmin/timerules'"><i class="bi bi-clock-fill"></i>&nbsp;{{languageService.getItem(575)}}</a></li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" [routerLink]="'/systemadmin/rules'"><i class="bi bi-gear-fill"></i>&nbsp;{{languageService.getItem(114)}}</a></li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" [routerLink]="'/systemadmin/guides'"><i class="bi bi-info-circle"></i>&nbsp;{{languageService.getItem(1390)}}</a></li>
      </ul>
    </div>
    <div class="navbar-collapse mt-5" *ngIf="showButton&&permission">
      <div class="dropdown" style="position:relative;">
        <button class="btn btn-swe" (click)="addToHomeScreen()">Installera app</button>
        <div class="dropdown-menu dropdown-menu-start p-3" [ngClass]="{'show': showText}">
          För att installera denna app på din enhet tryck på Meny knappen och sen på 'Lägg till på min hemskärm' knappen.
        </div>
      </div>
    </div>
    <div class="navbar-collapse mt-5">
      <ul class="navbar-nav">
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" [routerLink]="'/help'" *ngIf="permissionService.permissions&&!permissionService.user.UpdatePassword&&permissionService.permissions.Help > 0"><i class="bi bi-question-lg"></i>&nbsp;{{languageService.getItem(742)}}</a></li>
        <li class="nav-item"><a class="nav-link swe-nav-link" (click)="manageMenu()" [routerLink]="'/login'" [queryParams]="{logout: '1'}"><i class="bi bi-door-closed-fill"></i>&nbsp;{{languageService.getItem(5)}}</a></li>
      </ul>
    </div>
    <div class="navbar-collapse mt-5" *ngIf="permissionService.permissions && permissionService.permissions.Logo&&permissionService.permissions.Logo.length>0">
      <img alt="Logo" sweImage imageFolder="images" [imageFile]="permissionService.permissions && permissionService.permissions.Logo" style="width:100%;" />
    </div>
    <div class="navbar-collapse mt-5">
      <span class="navbar-text small">Version {{version}}</span>
    </div>
  </div>
</nav>
