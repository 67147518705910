import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ChartType, ChartDataset, ChartOptions } from 'chart.js';
import { Subscription } from 'rxjs';

import { LanguageService } from '../_services/language.service';
import { DataService } from '../_services/data.service';
import { SettingService } from '../_services/setting.service';
import { PermissionService } from '../_services/permission.service';
import { PropertyService } from '../_services/property.service';
import { ListService } from '../_services/list.service';
import { DateTimeService } from '../_services/datetime.service';
import { GeneralService } from '../_services/general.service';
import { ViewCacheService } from '../_services/viewcache.service';
import { AlertService } from '../_services/alert.service';
import { ListUtility } from '../_utilities/list.utility';


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html'
})
export class ReportsComponent implements OnInit {

  private _subscriptions: Subscription[] = [];
  private _createvisible: boolean = false;
  private _hascheckicon: boolean = false;
  private _downloadmenu: boolean = false;
  private _templates: any[] = [];
  private _reportroles: any[] = [];
  private _reportvisibility: any[] = [];
  private _maintypes: any[] = [];
  private _excludetypes: any[] = [];
  private _reportproperties: any = {};
  private _groupbyproperties: any[] = [];
  private _downloadsettingmenu: boolean = false;
  private _encodings: any[] = [];
  private _encoding: number = 0;
  private _delimeters: any[] = [];
  private _delimeter: number = 0;
  private _reportmenu: boolean = false;
  private _editmenu: boolean = false;
  private _editpermissions: number = 0;
  private _reports: any;
  private _top: number = 30;
  private _multiple: number = 1;
  private _loading: boolean = false;
  private _more: boolean = false;
  private _all: boolean = false;
  private _hits: number = 0;
  private _rows: any[] = [];
  private _sorted: any[] = [];
  private _unsorted: any[] = [];
  private _headers: any[] = [];
  private _sum: any[] = [];
  private _sumlength: any[] = [];
  private _allchecked: boolean = false;
  private _actionmenu: boolean = false;
  private _mappedsettings: any = {};
  private _graphvisible: number[] = [];
  private _statistics = { data: [], labels: [], series: [], idList: [] };
  private _charttype: ChartType = 'bar';
  private _chartdata: ChartDataset[] = [];
  private _chartlabels: string[] = [];
  private _chartoptions: ChartOptions = {};
  private _chartlegend: boolean = true;
  private _activerow: number = -1;
  private _listutility: ListUtility = new ListUtility();
  private _removeOrder: boolean = false;
  private _removeSort: boolean = false;
  private _systemcolumns: number = 5;
  private _intervals: any[];
  private _excludedRows: number = 0;

  constructor(
    public languageService: LanguageService,
    public settingService: SettingService,
    public permissionService: PermissionService,
    public generalService: GeneralService,
    private dataService: DataService,
    private propertyService: PropertyService,
    private listService: ListService,
    private dateTimeService: DateTimeService,
    private viewCacheService: ViewCacheService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute
  ) {

    settingService.initView(
      1 | 2 | 8 | 16 | 32,
      ['Booking', 'User', 'Level', 'BookingUser', 'UserMarkedDate'],
      [{ type: 'booking', key: 'activityoptiononly' }]
    );

    this._subscriptions.push(settingService.onRefresh$
      .subscribe(() => {
      this.search();
      }));

    this._subscriptions.push(settingService.onSave$
      .subscribe((e) => {
        //Save
        this.save();
      })); 
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => s.unsubscribe());
  }

  ngOnInit() {

    this._intervals = [
      { Name: this.languageService.getItem(526), Id: 1 },
      { Name: this.languageService.getItem(527), Id: 2 },
      { Name: this.languageService.getItem(1395), Id: 6 },
      { Name: this.languageService.getItem(528), Id: 3 },
      { Name: this.languageService.getItem(693), Id: 4 },
      { Name: this.languageService.getItem(694), Id: 5 },
    ];

    //MappedSetting
    this.mappedSettings();

    this.route.paramMap.subscribe(params => {
      let id = Number(params.get('id'));
      this.loadReports(id == 0 ? this.settingService.report.id : id); 
    });

    //this._listutility.hasdataaccess = false;
    this._listutility.dataaccessname = '4';

    //Encodings
    this._encoding = parseInt(localStorage.getItem('csv-encoding')) || 1252;
    this._encodings = [{ Id: 1252, Name: "Latin-1" }, { Id: 65001, Name: "UTF-8" }, { Id: 1200, Name: "UTF-16 (Little Endian)" }, { Id: 1201, Name: "UTF-16 (Big Endian)" }];

    //Delimeters
    this._delimeter = parseInt(localStorage.getItem('csv-delimeter')) || 0;
    this._delimeters = [{ Id: 0, Name: this.languageService.getItem(771) }, { Id: 1, Name: this.languageService.getItem(772) }, { Id: 2, Name: this.languageService.getItem(773) }];

    //Templates
    this._templates = this.listService.formatArray(this.generalService.templates, ['18'], 'Extra');

    //Roles
    this.loadRoles();

    //MainTypes
    this.loadMainTypes();

    //ReportTypes
    this.loadReportTypes();
  }


  //Properties
  public get downloadmenu() {
    return this._downloadmenu;
  }
  public set downloadmenu(val) {
    this._downloadmenu = val;
  }
  public get templates() {
    return this._templates;
  }
  public get downloadsettingmenu() {
    return this._downloadsettingmenu;
  }
  public set downloadsettingmenu(val) {
    this._downloadsettingmenu = val;
  }
  public get encodings() {
    return this._encodings;
  }
  public get encoding() {
    return this._encoding;
  }
  public get delimeters() {
    return this._delimeters;
  }
  public get delimeter() {
    return this._delimeter;
  }
  public get reportmenu() {
    return this._reportmenu;
  }
  public set reportmenu(val) {
    this._reportmenu = val;
  }
  public get editmenu() {
    return this._editmenu;
  }
  public set editmenu(val) {
    this._editmenu = val;
  }
  public get editpermissions() {
    return this._editpermissions;
  }
  public get maintypes() {
    return this._maintypes;
  }
  public get excludetypes() {
    return this._excludetypes;
  }
  public get reportproperties() {
    return this._reportproperties;
  }
  public get groupbyproperties() {
    let list = [];
    this._groupbyproperties.forEach((property) => {
      if (
        (this.settingService.report.main == 0 && property.CatType == 'User')
        ||
        (this.settingService.report.main == 1 && property.CatType == 'Level' && property.Id > 0)
        ||
        (this.settingService.report.main == 2 && (property.CatType == 'Booking' || (property.CatType == 'User' && property.Id < 0)))
        ||
        (this.settingService.report.main == 4 && property.CatType == 'UserMarkedDate')
        ||
        (this.settingService.report.main == 5 && property.CatType == 'BookingUser')
      ) {
        list.push(property);
      }
    });

    return list;
  }
  public get reportvisibility() {
    return this._reportvisibility;
  }
  public get reports() {
    return this._reports;
  }
  public get loading() {
    return this._loading;
  }
  public get more() {
    return this._more;
  }
  public get all() {
    return this._all;
  }
  public get headers() {
    return this._headers;
  }
  public get hits() {
    return this._hits;
  }
  public get rows() {
    return this._rows;
  }
  public get sum() {
    return this._sum;
  }
  public get allchecked() {
    return this._allchecked;
  }
  public set allchecked(val) {
    this._allchecked = val;
  }
  public get actionmenu() {
    return this._actionmenu;
  }
  public set actionmenu(val) {
    this._actionmenu = val;
  }
  public get sumlength() {
    return this._sumlength;
  }
  public get hasicons() {
    return !this.settingService.report.groupby || this.settingService.report.groupby == 0;
  }
  public get hascheckicon() {
    return this._hascheckicon; 
  }
  public get createvisible() {
    return this._createvisible;
  }
  public get graphvisible() {
    return this._graphvisible;
  }
  public get charttype() {
    return this._charttype;
  }
  public get chartdata() {
    return this._chartdata;
  }
  public get chartlabels() {
    return this._chartlabels;
  }
  public get chartlegend() {
    return this._chartlegend;
  }
  public get chartoptions() {
    return this._chartoptions;
  }
  public get activerow() {
    return this._activerow;
  }
  public set activerow(val) {
    this._activerow = val;
  }
  public get listutility() {
    return this._listutility;
  }
  public get removeOrder() {
    return this._removeOrder;
  }
  public set removeOrder(val) {
    this._removeOrder = val;
  }
  public get removeSort() {
    return this._removeSort;
  }
  public set removeSort(val) {
    this._removeSort = val;
  }
  public get sorted() {
    return this._sorted;
  }
  //System columns
  public get systemcolumns() {
    //Return number of columns
    return this._systemcolumns;
  }
  public get lastsystemcolumnindex() {
    //Return last column index
    return this._systemcolumns-1;
  }
  public get firstnonsystemcolumnindex() {
    //Return first non column index
    return this._systemcolumns;
  }

  public get intervals() {
    return this._intervals;
  }

  public get excludedRows() {
    return this._excludedRows;
  }


  //Methods
  public print() {
    window.print();
  }
  public interval() {
    let result = '';

    result += this.dateTimeService.format(this.settingService.report.start, 'yyyy-MM-dd');

    result += ' - ';

    result += this.dateTimeService.format(this.settingService.report.end, 'yyyy-MM-dd');

    return result;
  }
  public search(getmore: boolean = false, getall: boolean = false) {

    if (this._loading) { return; }

    this._loading = true;

    this._more = false;
    this._all = false;

    if (getmore) {
      this._multiple++;
    }
    else {
      this._top = 30;
      this._multiple = 1;
      this._rows = [];
      this._unsorted = [];
      this._excludedRows = 0;
    }

    if (getall) {
      this._top = 0;
      this._excludedRows = 0;
    }

    this.manageProperties();

    let filter = {
      Top: this._top,
      Multiple: this._multiple,
      //Report
      Id: this.settingService.report.id,
      Main: this.settingService.report.main,
      ReportList: this.settingService.report.reports,
      Owner: this.settingService.report.visibility,
      ExcludeObject: this.settingService.report.excludeobject,
      Order: this.settingService.report.order,
      GroupBy: this.settingService.report.groupby ? this.settingService.report.groupby : 0,
      //Booking
      Start: this.settingService.start('report'),
      End: this.settingService.end('report'),
      IntervalType: this.settingService.booking.intervaltype,
      Weekdays: this.settingService.booking.weekdays,
      CheckIn: this.settingService.booking.checkin,
      Reservation: this.settingService.booking.reservation,
      ExcludeEmptyShiftWhenBookingTypeFiltrering: this.settingService.booking.excludeemptyshiftbookingtype == 1 ? true : false,
      ReplaceableAsNoSlot: this.settingService.booking.replaceableasnoslot,
      Personal: this.settingService.booking.personal,
      ShiftType: this.settingService.booking.shifttype,
      StatusList: this.settingService.booking.status,
      TypeList: this.settingService.booking.bookingtype.join(),
      BookingOwnerId: this.settingService.booking.owner,
      ActivityTypes: this.settingService.booking.activitytypes,
      ActivityOption: this.settingService.booking.activityoption,
      TimeTypes: this.settingService.booking.timetypes,
      ShiftLevelGroups: this.settingService.booking.levelgroups.join(),
      ShiftLevelList: this.settingService.levelList(2),
      //User
      UserSearch: this.settingService.user.search,
      UserList: this.settingService.userList(),
      RoleList: this.settingService.user.roles.join(),
      StartAvailable: this.settingService.start('report'),
      EndAvailable: this.settingService.end('report'),
      Available: this.settingService.user.availability,
      AvailableTypes: this.settingService.user.availabilitylist.join(),
      Employment: this.settingService.user.employment,
      EmploymentCategories: this.settingService.user.employmentcategories,
      FrameworkContracts: this.settingService.user.frameworkcontracts,
      Contract: this.settingService.user.contract,
      Contracts: this.settingService.user.contracts,
      Booking: this.settingService.user.bookingtype,
      Active: this.settingService.user.activity,
      UserLevelGroups: this.settingService.user.levelgroups.join(),
      UserLevelList: this.settingService.levelList(1),
      //Properties
      PropertyList: this.settingService.property.properties,
      UseOR: this.settingService.property.useor
    };

    this.dataService.tokenRequest('/api/v1/reports/search', 'Post', filter)
      .subscribe((res) => {

        if (res) {

          let collectheader = this._multiple == 1;
          res.Reports.forEach((item) => {
            if (collectheader) {
              this.manageHeader(item);
              collectheader = false;
            }
            else {
              this.manageRow(item);
            }
          });

          this._listutility.rows = this._rows;
          this._hits = this._rows.length;
          this._more = res.More;
          this._excludedRows += res.ExcludedRows
          this._all = res.More;

          if (this._graphvisible.length > 0) {
            this.loadStatistics();
          }

          this._editpermissions = 0;
          if (this.settingService.report.visibility == 0) {
            //All
            this._editpermissions = this.permissionService.permissions.ReportAll ? 3 : 1;
          }
          else if (this.settingService.report.visibility > 0) {
            //Personal
            this._editpermissions = 3;
          }
          else {
            //Roles
            let role = this.listService.find(this._reportroles, 'Id', -1 * this.settingService.report.visibility);
            if (role) {
              this._editpermissions = role.Access;
            }
          }

          //Sort
          if (this._sorted.length > 0) {
            this.sort(null);
          }

          this._loading = false;
        }

      });
  }
  public sort(header) {
    this.headers.forEach((head) => {
      if (header == head) {
        if (typeof header.property.Sort == "undefined") {
          head.property.Sort = 0;
        }

        header.property.Sort++;
        if (header.property.Sort > 2) {
          header.property.Sort = 0;
        }
      }

    });

    this.sortcolumn(header);
  }
  public move(header, step) {

    let index = this._headers.indexOf(header);
    if (index > -1) {
      let header1 = this._headers[index];
      header1.index = index + step;
      let header2 = this._headers[index + step];
      header2.index = index;
      this._headers[index] = header2;
      this._headers[index + step] = header1;

      this._sorted.forEach((sorted) => {
        let sortitem = this.listService.find(this._headers, 'id', sorted.id);
        if (sortitem) {
          sorted.index = sortitem.index;
        }
      });

      this._rows.forEach((item) => {
        let item1 = item[index];
        let item2 = item[index + step];
        item[index] = item2;
        item[index + step] = item1;
      });

      //Set custom order
      this.settingService.report.order = [];
      this._headers.forEach((header, idx) => {
        if (idx > this.lastsystemcolumnindex) {
          this.settingService.report.order.push(header.id);
        }
      });
    }

  }
  public changereport(report) {
    this._reportmenu = false;

    this._removeOrder = false;
    this._removeSort = false;

    this.settingService.resetFilter(null);

    if (report.Id == 0) {
      this.settingService.resetReport();
      this.settingService.reset();
      this.settingService.report.visibility = this.permissionService.user.Id;
    }
    else {
      this.settingService.report.id = report.Id;
      this.settingService.report.main = report.Main;
      this.settingService.report.name = report.Name;
      this.settingService.report.description = report.Description;
      this.settingService.report.visibility = report.OwnerId;
      this.settingService.report.excludeobject = report.ExcludeObject;
      this.settingService.report.groupby = report.GroupBy;
      this.settingService.report.intervaltype = report.IntervalType;
      if (report.IntervalType > 0) {
        this.settingService.report.datetimespan = report.IntervalType;
        this.setDate(this.spanstart());
      }
      this.settingService.report.order = [];
      if (typeof report.Order != "undefined" && report.Order != null && report.Order.length > 0) {
        report.Order.split(',').forEach((item) => {
          this.settingService.report.order.push(parseInt(item));
        });
      }

      this.settingService.report.reports = [];
      if (typeof report.Properties != "undefined" && report.Properties != null && report.Properties.length > 0) {
        report.Properties.split(',').forEach((item) => {
          this.settingService.report.reports.push(parseInt(item));
        });
      }

      if (typeof report.Sort != 'undefined' && report.Sort.length > 0) {
        this._sorted = JSON.parse(report.Sort);
      }
      else {
        this._sorted = [];
      }

      this._graphvisible = [];
      this.loadSettings(report.Settings);
      this.manageExcludes();
    }

    //Category specifics
    let category = this.settingService.report.main;
    if (category == 0) {
      //Users
      this._createvisible = (this.permissionService.permissions.Users > 1);
      this._hascheckicon = this.permissionService.permissions.MultiUser > 0;
    }
    else if (category == 1) {
      //Levels
      this._createvisible = (this.permissionService.permissions.Levels > 1);
      this._hascheckicon = this.permissionService.permissions.MultiLevel > 0;
    }
    else if (category == 2) {
      //Bookings
      this._createvisible = (this.permissionService.permissions.Bookings > 1);
      this._hascheckicon = this.permissionService.permissions.MultiShift > 0;
    }
    else if (category == 5) {
      //Timereports
      this._createvisible = false;
      this._hascheckicon = this.permissionService.permissions.MultiTimereport > 0;
    }
    else if (category == 4) {
      //UserMarkedDates
      this._createvisible = (this.permissionService.permissions.MarkedDates > 1);
      this._hascheckicon = this.permissionService.permissions.MultiMarkedDate > 0;
    }

    if (this.settingService.report.groupby && this.settingService.report.groupby > 0) {
      this._hascheckicon = false;
    }

    this.search();

    if (report.IntervalType > 0) {
      this.settingService.viewrefresh('datepager_reload');
    }

    if (report.Id > 0) {
      window.history.replaceState({}, '', 'reports/' + report.Id);
    }
    else {
      window.history.replaceState({}, '', 'reports/');
    }
  }
  public open(id, e) {

    if ((this.settingService.report.groupby && this.settingService.report.groupby > 0)) {
      return;
    }

    let url = '';
    let category = this.settingService.report.main;
    if (category == 0) {
      url = '/users/' + id;
    }
    else if (category == 1) {
      url = '/levels/' + id;
    }
    else if (category == 2) {
      url = '/bookings/' + id;
    }
    else if (category == 5) {
      url = '/timereports/' + id;
    }
    else if (category == 4) {
      url = '/usermarkeddates/' + id;
    }

    if (e.ctrlKey || e.metaKey) {
      window.open(url, '_blank');
    }
    else {
      this.router.navigate([url]);
    }
  }
  public action(option) {
    this._actionmenu = false;

    let items = [];
    this._rows.forEach((row) => {
      if (row.checked) {
        items.push(row[0]);
      }
    });

    if (items.length > 0) {

      let category = this.settingService.report.main;
      if (category == 0) {
        this.viewCacheService.add('multi_users', items);

        this.router.navigate(['/users/action/' + option + '/']);
      }
      else if (category == 1) {
        this.viewCacheService.add('multi_levels', items);

        this.router.navigate(['/levels/action/' + option + '/']);
      }
      else if (category == 2) {
        this.viewCacheService.add('multi_bookings', items);

        this.router.navigate(['/bookings/action/' + option + '/']);
      }
      else if (category == 5) {
        this.viewCacheService.add('multi_timereports', items);

        this.router.navigate(['/timereports/action/' + option + '/']);
      }
      else if (category == 4) {
        this.viewCacheService.add('multi_usermarkeddates', items);

        this.router.navigate(['/usermarkeddates/action/' + option + '/']);
      }
    }

  }
  public multitimereport() {
    this._actionmenu = false;

    let items = [];
    this._rows.forEach((row) => {
      if (row.checked) {
        if (row[3] && row[3].Timereports) {
          row[3].Timereports.forEach((timereport) => {
            items.push(timereport);
          });
        }
      }
    });

    if (items.length > 0) {

      let category = this.settingService.report.main;
      if (category == 0) {
        this.viewCacheService.add('multi_timereports', items);

        this.router.navigate(['/timereports/action/multi/']);
      }
    }
  }
  public send(type) {
    this._actionmenu = false;

    let items = [];
    this._rows.forEach((row) => {
      if (row.checked) {
        items.push(row[0]);
      }
    });

    if (items.length > 0) {

      let category = this.settingService.report.main;
      if (category == 0) {
        let filter = {
          Top: 0,
          UserList: items.join(',')
        };

        this.dataService.tokenRequest('/api/v1/users/search', 'POST', filter)
          .subscribe((res) => {

            this.viewCacheService.add('message_users', res.Users);

            if (type == 1) {
              this.router.navigate(['/messages/send/email']);
            }
            else if (type == 3) {
              this.router.navigate(['/messages/send/sms']);
            }

          });
      }
      else if (category == 1) {
        let filter = {
          Top: 0,
          LevelList: items.join(',')
        };

        this.dataService.tokenRequest('/api/v1/levels/search', 'POST', filter)
          .subscribe((res) => {

            this.viewCacheService.add('message_levels', res.Levels);

            if (type == 1) {
              this.router.navigate(['/messages/send/email']);
            }
            else if (type == 3) {
              this.router.navigate(['/messages/send/sms']);
            }

          });
      }
    }

  }
  public changeencoding(id, e) {
    e.stopPropagation();
    this._downloadsettingmenu = false;
    this._encoding = id;
    localStorage.setItem('csv-encoding', id);
  }
  public changedelimeter(id, e) {
    e.stopPropagation();
    this._downloadsettingmenu = false;
    this._delimeter = id;
    localStorage.setItem('csv-delimeter', id);
  }
  public download(template, e) {
    e.stopPropagation();
    this._downloadsettingmenu = false;

    let filter = {
      Top: 0,
      Multiple: 1,
      //Report
      Id: this.settingService.report.id,
      Main: this.settingService.report.main,
      ReportList: this.settingService.report.reports,
      Owner: this.settingService.report.visibility,
      ExcludeObject: this.settingService.report.excludeobject,
      Order: this.settingService.report.order,
      GroupBy: this.settingService.report.groupby ? this.settingService.report.groupby : 0,
      //Booking
      Start: this.settingService.start('booking'),
      End: this.settingService.end('booking'),
      IntervalType: this.settingService.booking.intervaltype,
      Weekdays: this.settingService.booking.weekdays,
      CheckIn: this.settingService.booking.checkin,
      Reservation: this.settingService.booking.reservation,
      ReplaceableAsNoSlot: this.settingService.booking.replaceableasnoslot,
      Personal: this.settingService.booking.personal,
      ShiftType: this.settingService.booking.shifttype,
      StatusList: this.settingService.booking.status,
      TypeList: this.settingService.booking.bookingtype.join(),
      BookingOwnerId: this.settingService.booking.owner,
      ShiftLevelGroups: this.settingService.booking.levelgroups.join(),
      ShiftLevelList: this.settingService.levelList(2),
      //User
      UserSearch: this.settingService.user.search,
      UserList: this.settingService.userList(),
      RoleList: this.settingService.user.roles.join(),
      StartAvailable: this.settingService.start('booking'),
      EndAvailable: this.settingService.end('booking'),
      Available: this.settingService.user.availability,
      AvailableTypes: this.settingService.user.availabilitylist.join(),
      Booking: this.settingService.user.bookingtype,
      Active: this.settingService.user.activity,
      UserLevelGroups: this.settingService.user.levelgroups.join(),
      UserLevelList: this.settingService.levelList(1),
      //Properties
      PropertyList: this.settingService.property.properties,
      UseOR: this.settingService.property.useor,
      //Download
      Encoding: this._encoding,
      Delimeter: this._delimeter,
      Template: template
    };

    if (filter.Main > -1) {

      this.dataService.tokenRequest('/api/v1/reports/download', 'POST', filter, 'blob', 'response')
        .subscribe((res) => {

          let filename = res.headers.get('content-disposition').split("filename=")[1].split(";")[0];

          //let now = new Date();
          //let filename = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate() + '_' + now.getHours() + ':' + now.getMinutes() + ':' + now.getSeconds();
          //if (res.type == 'application/xml') {
          //  filename += '.xml';
          //}
          //else if (res.type == 'application/pdf') {
          //  filename += '.pdf';
          //}
          //else if (res.type == 'text/html') {
          //  filename += '.html';
          //}
          //else if (res.type == 'text/plain') {
          //  filename += '.txt';
          //}
          //else {
          //  let fileArray = res.type.split('/');
          //  if (fileArray[0] == 'custom' && fileArray.length == 2) {
          //    filename += '.' + fileArray[1];
          //  }
          //  else {
          //    filename += '.csv';
          //  }
          //}

          let reader = new FileReader();
          reader.readAsDataURL(res.body);
          reader.onloadend = (e) => {

            let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

            let hiddenElement = document.createElement('a');
            //if (typeof navigator !== "undefined" && navigator.msSaveOrOpenBlob) {
            //  //IE
            //  navigator.msSaveOrOpenBlob(res, filename);
            //}
            //else
            if (typeof hiddenElement.download != 'undefined' && !isSafari) {
              //Support HTML5 download attribute (Chrome 14+, Firefox20+, IE13+, Opera15+)
              hiddenElement.href = reader.result.toString();
              hiddenElement.target = '_blank';
              hiddenElement.download = filename;

              document.body.appendChild(hiddenElement);

              hiddenElement.click();
            }
            else {
              let url = '';
              try {
                let fileArr = reader.result.toString().split(';');
                let blob = this.settingService.b64toBlob(fileArr[1].replace("base64,", ""), fileArr[0].replace("data:", ""));

                url = URL.createObjectURL(blob);
              }
              catch (e) {
                //Final solution
                let is_chrome_ios = /CriOS\/[\d]+/.test(navigator.userAgent);
                url = is_chrome_ios ? reader.result.toString() : reader.result.toString().replace(/^data:[^;]*;/, 'data:attachment/file;');
              }

              var popup = window.open(url, '_blank');
              if (!popup) {
                window.location.href = url;
              }
            }

          }

        });
    }

  }
  public save() {

    if (typeof this.settingService.report.name == "undefined" || this.settingService.report.name.length == 0) {
      this.alertService.Add({ message: this.languageService.getItem(313), type: 'danger' });
      return;
    }

    if (this.settingService.report.reports.length == 0) {
      this.alertService.Add({ message: this.languageService.getItem(885), type: 'danger' });
      return;
    }

    this.manageProperties();

    if (this._removeOrder) {
      this.settingService.report.order = [];
    }
    if (this._removeSort) {
      this._sorted = [];
    }

    let id = this.settingService.report.id;

    let verb = 'POST';
    let path = '/api/v1/reports/'
    if (id > 0) {
      verb = 'PUT';
      path += id;
    }

    let dto = {
      Name: this.settingService.report.name,
      Description: this.settingService.report.description,
      Main: this.settingService.report.main,
      Properties: this.settingService.report.reports.join(','),
      OwnerId: this.settingService.report.visibility,
      ExcludeObject: this.settingService.report.excludeobject,
      Order: this.settingService.report.order.join(','),
      IntervalType: this.settingService.report.intervaltype,
      GroupBy: this.settingService.report.groupby ? this.settingService.report.groupby : 0,
      Settings: this.saveSettings()
    };

    if (this._sorted.length > 0) {
      dto['Sort'] = JSON.stringify(this._sorted);
    }
    else {
      dto['Sort'] = '';
    }

    this.dataService.tokenRequest(path, verb, dto, 'text')
      .subscribe((res) => {
        this.alertService.Add({ message: res, type: 'success' });

        this.generalService.reloadReports()
          .subscribe(() => {
            //Load current or last (when new)
            this.loadReports(id > 0 ? id : -1);

            this._editmenu = false;
          });
      });

  }
  public copy() {
    this.settingService.report.id = 0;
    this.settingService.report.name = '';
    this.settingService.report.description = '';
    this.settingService.report.visibility = this.permissionService.user.Id;
    this._editpermissions = 3;
  }
  public delete() {

    let id = this.settingService.report.id;
    if (id == 0) {
      return;
    }

    this.dataService.tokenRequest('/api/v1/reports/' + id, 'DELETE', {}, 'text')
      .subscribe((res) => {
        this.alertService.Add({ message: res, type: 'success' });

        this.generalService.reloadReports()
          .subscribe(() => {
            //Load first
            this.loadReports(0);

            this._editmenu = false;
          });
      });
  }
  public graph(header, e) {
    e.stopPropagation();

    if (header.property.Graph == 1) {
      header.property.Graph = 2;

      this._graphvisible.push(header.id);
    }
    else if (header.property.Graph == 2) {
      header.property.Graph = 1;

      let index = this._graphvisible.indexOf(header.id);
      if (index > -1) {
        this._graphvisible.splice(index, 1);
      }
    }

    if (this._graphvisible.length > 0) {
      this.loadStatistics();
    }
  }
  public goto() {
    let url = '/reports/' + this.settingService.report.id;

    window.open(url, '_blank');
  }
  public manageExcludes() {
    this._excludetypes = [];
    if (this.permissionService.permissions.Shift) {
      if (this.settingService.report.main != 5) {
        //BookingUsers
        if (this.settingService.report.main == 0 || this.settingService.report.main == 4) {
          //Shift
          this._excludetypes.push({ Id: 8, Name: this.languageService.getItem(4) });
        }
        else if (this.settingService.report.main == 1) {
          //Levels
          this._excludetypes.push({ Id: 8, Name: this.languageService.getItem(949) });
        }
        else {
          //Users
          this._excludetypes.push({ Id: 8, Name: this.languageService.getItem(2) });
        }
      }
    }
    if (this.permissionService.permissions.MarkedDates > 0) {
      if (this.settingService.report.main != 4) {
        //UserMarkedDates
        this._excludetypes.push({ Id: 4, Name: this.languageService.getItem(51) });
      }
    }
  }



  //Reports
  private manageProperties() {

    if (this.settingService.report.order.length > 0) {
      this.settingService.report.reports.forEach((property) => {
        let order = this.settingService.report.order.indexOf(property);
        if (order == -1) {
          //Add Custom Order
          this.settingService.report.order.push(property);
        }
      });

      //this.settingService.report.order.forEach((order, index) => {
      for (let i = this.settingService.report.order.length - 1; i >= 0; i--) {
        let order = this.settingService.report.order[i];
        let property = this.settingService.report.reports.indexOf(order);
        if (property == -1) {
          //Remove Custom Order
          this.settingService.report.order.splice(i, 1);
        }
      }
      //});
    }

    //this._sorted.forEach((sorted, index) => {
    for (let i = this._sorted.length - 1; i >= 0; i--) {
      let sorted = this._sorted[i];
      let property = this.settingService.report.reports.indexOf(sorted.id);
      if (property == -1) {
        //Remove Sort
        this._sorted.splice(i, 1);
      }
    }
    //});

  }
  private loadRoles() {
    this.dataService.tokenRequest('/api/v1/roles/type/report', 'GET', {})
      .subscribe((res) => {
        this._reportroles = res;

        let groupbyList: any[] = [];
        res.forEach((item) => {
          let groupby = this.listService.find(groupbyList, 'Name', item.Category);
          if (groupby) {
            groupby.Items.push({ Id: item.Id, Name: item.Name });
          }
          else {
            groupbyList.push({ Name: item.Category, Category: item.CatType, Items: [{ Id: item.Id, Name: item.Name }] });
          }
        });


        this._reportvisibility = [];
        if (this.permissionService.permissions.ReportAll) {
          this._reportvisibility.push({ Name: '', Category: 0, Items: [{ Id: 0, Name: this.languageService.getItem(101) }, { Id: this.permissionService.user.Id, Name: this.languageService.getItem(834) }] });
        }
        else {
          this._reportvisibility.push({ Name: '', Category: 0, Items: [{ Id: this.permissionService.user.Id, Name: this.languageService.getItem(834) }] });
        }
          if (res.length > 0) {
          this._reportvisibility.push({ Name: this.languageService.getItem(82), Category: 1, Items: [] });
          res.forEach((role) => {
            this._reportvisibility[this._reportvisibility.length - 1].Items.push({ Id: -1 * role.Id, Name: role.Name });
          });
        }
      });
  }
  private loadMainTypes() {
    this._maintypes = [];
    this._maintypes.push({ Id: 0, Name: this.languageService.getItem(2) }); //Users
    if (this.permissionService.permissions.Shift) {
      this._maintypes.push({ Id: 2, Name: this.languageService.getItem(4) }); //Shift
      this._maintypes.push({ Id: 5, Name: this.languageService.getItem(52) }); //Timereport
    }
    this._maintypes.push({ Id: 1, Name: this.languageService.getItem(3) }); //Levels
    if (this.permissionService.permissions.MarkedDates > 0) {
      this._maintypes.push({ Id: 4, Name: this.languageService.getItem(51) }); //Availability
    }
  }
  private loadReportTypes() {

    this.dataService.tokenRequest('/api/v1/properties/type/report', 'GET').subscribe(res => {
      if (res) {

        let groupbyList: any[] = [];
        res.forEach((item) => {
          let groupby = this.listService.find(groupbyList, 'Name', item.Category);
          if (groupby) {
            groupby.Items.push({ Id: item.Id, Name: item.Name });
          }
          else {
            groupbyList.push({ Name: item.Category, Category: item.CatType, Items: [{ Id: item.Id, Name: item.Name }] });
          }
        });

        this._reportproperties = groupbyList;
        this._groupbyproperties = res;
      }
    });
    
  }
  private loadReports(id) {

    let sort = 0;

    let grouped = [{ Id: this.permissionService.user.Id, Name: this.languageService.getItem(834), Sort: sort++ }];
    this.generalService.roles.forEach((role) => {
      grouped.push({ Id: -1 * role.Id, Name: role.Name, Sort: sort++ });
    });
    grouped.push({ Id: 0, Name: this.languageService.getItem(101), Sort: sort++ });

    this._reports = this.listService.groupByInt(
      this.generalService.reports,
      'OwnerId',
      grouped);

    let report;
    if (id > 0) {
      //Load chosen
      report = this.listService.find(this.generalService.reports, 'Id', id);
    }

    if (report) {
      //Load chosen
      this.changereport(report);
    }
    else if (id == 0 && this.generalService.reports.length > 0) {
      //Load first
      this.changereport(this.generalService.reports[0]);
    }
    else if (id == -1 && this.generalService.reports.length > 0) {
      //Load last
      let highestId = 0;
      let last = null;
      this.generalService.reports.forEach((r) => {
        if (r.Id > highestId) {
          highestId = r.Id;
          last = r;
        }
      });
      if (last != null) {
        //Last
        this.changereport(last);
      }
      else {
        //Load empty
        this.search();
      }
    }
    else {
      //Load empty
      this.search();
    }
  }

  //Header
  private manageHeader(item) {
    this._headers = [];
    this._sum = [];
    this._sumlength = [];
    this._statistics.data = [];
    this._statistics.labels = [];
    this._statistics.series = [];
    this._statistics.idList = [];

    item.forEach((header, idx) => {
      let property: any = {};
      let sortitem = null;
      if (header > 0) {
        let original = this.propertyService.getProperty(header);
        property.Name = original.Name;
        property.Type = original.Type;
        property.SubType = original.SubType;
        property.ShowImage = original.ShowImage;
        property.Items = original.Items;

        sortitem = this.listService.find(this._sorted, 'id', original.Id);
      }
      else if (header < 0) {
        //Competence
        let original = this.propertyService.getProperty(header * -1);
        property.Name = original.Name;
        if (original.CatType == 'User') {
          property.Name = property.Name + " " + this.languageService.getItem(634);
        }
        else if (original.CatType == 'Level') {
          property.Name = property.Name + " (" + this.languageService.getItem(59) + ')';
        }
        property.Type = original.Type;
        property.ShowImage = original.ShowImage;
        property.Items = original.Items;

        sortitem = this.listService.find(this._sorted, 'id', original.Id);
      }
      else if (header == 0) {
        //Role
        property.Name = this.languageService.getItem(766);
      }

      property.Sort = sortitem != null ? sortitem.sort : 0;
      property.Graph = 0;


      this._headers.push({ index: idx, id: header, property: property });

      if (property.Type == 'System.Double' || property.Type == 'System.Int32' || property.SubType == 'System.Double' || property.SubType == 'System.Int32') {
        property.Graph = 1;
        this._sum.push(0);
        this._sumlength.push(0);
        this._statistics.series.push(property.Name);
        this._statistics.data.push([]);
        this._statistics.idList.push(header);
      }
      else {
        this._sum.push('');
        this._sumlength.push('');
      }
    });
  }

  //Row
  private manageRow(item) {
    this._rows.push(item);
    this._unsorted.push(item);

    this._statistics.labels.push(item[1]);

    let datacounter = 0;
    item.forEach((cell, idx) => {

      if (cell != null && !Array.isArray(cell)) {
        cell = [cell];
      }

      if (typeof this._sum[idx] == "number") {
        //Integer or float cell
        if (this._statistics.data.length == 0) {
          this._statistics.data.push([]);
        }
        if (cell != null) {
          let subsum = 0;
          cell.forEach((line, idx2) => {
            if (line != null && line != 'SweError') {
              //Format line in cell
              cell[idx2] = this.generalService.formatdecimal(line);
              //Increase footer
              this._sum[idx] = this._sum[idx] + line;
              this._sumlength[idx]++;
              //Counter for sub footer in cell
              subsum = subsum + line;
            }
          });

          this._statistics.data[datacounter++].push(subsum);

          if (subsum > 0) {
            //Footer
            this._sum[idx] = this.generalService.formatdecimal(this._sum[idx]);
          }

          if (cell.length > 1) {
            //Multiple lines in cell, insert format sub footer
            item[idx].push('\t' + this.generalService.formatdecimal(subsum));
          }
        }
        else {
          this._statistics.data[datacounter++].push(0);
        }

      }
      else if (this._headers[idx].property.ShowImage) {
        //Image
        if (cell != null) {
          cell.forEach((line, idx2) => {
            if (line != null) {
              let src = '';
              this._headers[idx].property.Items.forEach((item) => {
                if (item.Id == line) {
                  cell[idx2] = item.Image + '|' + item.Name;
                }
              });
            }
          });
        }
      }
      else if (this._headers[idx].property.Type == "System.DateTime") {
        //DateTime cell
        if (cell != null) {
          cell.forEach((line, idx2) => {
            if (line != null) {
              //Format without seconds and milliseconds
              if (line != "0001-01-01T00:00:00") {
                cell[idx2] = this.dateTimeService.format(line, 'yyyy-MM-dd HH:mm');
              }
              else {
                cell[idx2] = '';
              }
            }
          });
        }
      }
      else if (this._headers[idx].property.Type == "System.Date") {
        //Date cell
        if (cell != null) {
          cell.forEach((line, idx2) => {
            if (line != null) {
              //Format without seconds and milliseconds
              if (line != "0001-01-01T00:00:00") {
                cell[idx2] = this.dateTimeService.format(line, 'yyyy-MM-dd');
              }
              else {
                cell[idx2] = '';
              }
            }
          });
        }
      }
      else if (this._headers[idx].property.Type == "System.Time") {
        //Time cell
        if (cell != null) {
          cell.forEach((line, idx2) => {
            if (line != null) {
              //Format without seconds and milliseconds
              if (line != "0001-01-01T00:00:00") {
                cell[idx2] = this.dateTimeService.format(line, 'HH:mm');
              }
              else {
                cell[idx2] = '';
              }
            }
          });
        }
      }

      item.ShowImage = this._headers[idx].property.ShowImage || false;
      item.Type = this._headers[idx].property.Type || '';

    });
  }

  //Sort
  private sortcolumn(header) {

    if (header != null) {
      if (header.property.Sort == 1) {
        this._sorted.push({
          id: header.id,
          index: header.index,
          sort: header.property.Sort
        });
      }
      else if (header.property.Sort == 2) {

        for (var i = 0; i < this._sorted.length; i++) {
          if (this._sorted[i].id == header.id) {
            this._sorted[i].sort = 2;
            break;
          }
        }
      }
      else if (header.property.Sort == 0) {

        for (var i = 0; i < this._sorted.length; i++) {
          if (this._sorted[i].id == header.id) {
            this._sorted.splice(i, 1);
            break;
          }
        }
      }
    }


    if (this._sorted.length == 0) {
      //Unsort list
      this._rows = [];
      this._unsorted.forEach((item) => {
        this._rows.push(item);
      });
    }
    else {
      //Sort list
      this._rows.sort((a, b) => {

        return this.sortitems(a, b, 0);
      });
    }
    
  }
  private sortitems(a, b, index) {

    let header = this._sorted[index];

    //Value A
    let arrayA = a[header.index];
    let valueA: any = '';
    if (arrayA != null && arrayA[arrayA.length - 1] != null) {
      valueA = arrayA[arrayA.length - 1]; //Last value
    }
    if (typeof valueA == "string") {
      if (valueA[0] == '\t') { //Subsum
        valueA = Number(valueA);
      }
      else {
        valueA = valueA.toLowerCase();
      }
    }
    //Value B
    let arrayB = b[header.index];
    let valueB: any = '';
    if (arrayB != null && arrayB[arrayB.length - 1] != null) {
      valueB = arrayB[arrayB.length - 1]; //Last value
    }
    if (typeof valueB == "string") {
      if (valueB[0] == '\t') { //Subsum
        valueB = Number(valueB);
      }
      else {
        valueB = valueB.toLowerCase();
      }
    }


    //Asc or Desc
    let asc = (header.sort == 1);

    //Compare
    if (valueA == valueB) {
      if (index < this._sorted.length - 1) {
        return this.sortitems(a, b, ++index);
      }
      else {
        // if they are equal, return 0 (no sorting)
        return 0;
      }
    }
    else if (valueA > valueB) {
      // if a should come after b, return 1
      return asc ? 1 /*asc*/ : -1 /*desc*/;
    }
    else {
      // if b should come after a, return -1
      return asc ? -1 /*asc*/ : 1 /*desc*/;
    }
  }

  private spanstart() {
    let start = this.settingService.report.start.getTime();
    let end = this.settingService.report.end.getTime();

    let day = new Date();

    if (start > day.getTime() || day.getTime() > end) {
      //Current span doesn't include today
      day = new Date(start);
    }

    return new Date(day.toDateString());
  }

  private setDate(date: Date) {

    if (this.settingService.report.datetimespan == 1) {
      //Day
      this.settingService.report.start = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      this.settingService.report.end = new Date(date.getFullYear(), date.getMonth(), date.getDate() );
    }
    else if (this.settingService.report.datetimespan == 2) {
      //Week
      let monday = this.dateTimeService.firstDayInWeek(date);
      this.settingService.report.start = monday;
      this.settingService.report.end = new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() + 6 );
    }
    else if (this.settingService.report.datetimespan == 6) {
      //OfficeWeek
      let monday = this.dateTimeService.firstDayInWeek(date);
      this.settingService.report.start = monday;
      this.settingService.report.end = new Date(monday.getFullYear(), monday.getMonth(), monday.getDate() + 4 );
    }
    else if (this.settingService.report.datetimespan == 3) {
      //Month
      let first = new Date(date.getFullYear(), date.getMonth(), 1);
      let last = new Date(first.getFullYear(), first.getMonth() + 1, first.getDate() - 1 );

      this.settingService.report.start = first;
      this.settingService.report.end = last;
    }
    else if (this.settingService.report.datetimespan == 4) {
      //Quarter
      let first = this.dateTimeService.firstDayInQuarter(date);
      let last = new Date(first.getFullYear(), first.getMonth() + 3, first.getDate() - 1 );

      this.settingService.report.start = first;
      this.settingService.report.end = last;
    }
    else if (this.settingService.report.datetimespan == 5) {
      //Year
      this.settingService.report.start = new Date(date.getFullYear(), 0, 1);
      this.settingService.report.end = new Date(date.getFullYear(), 11, 31 );
    }
    else {
      //Custom
      let start = this.settingService.report.start.getTime();
      let end = this.settingService.report.end.getTime();
      let diff = (end - start + this.dateTimeService.oneday) / this.dateTimeService.oneday;

      this.settingService.report.start = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      this.settingService.report.end = new Date(date.getFullYear(), date.getMonth(), date.getDate() + diff - 1);
    }
  }

  //Settings
  private loadSettings(list) {

    if (list) {
      /*Reset*/
      let start = this.settingService.report.start;
      let end = this.settingService.report.end;
      this.settingService.reset(false);
      this.settingService.report.start = start;
      this.settingService.report.end = end;
      /*****/

      if (list && list.length > 0) {
        let dbObjects = JSON.parse(list);
        for (let index in dbObjects) {
          let dbItem = dbObjects[index];
          let mappedItem = this._mappedsettings[index];

          if (mappedItem) {
            this.settingService[mappedItem.Object][mappedItem.Property] = dbItem;
          }
          else {
            console.log('Missing filter property (' + index + ')');
          }
        }
      }

      this.settingService.checkWatchdog();
    }

  }
  private saveSettings() {
    let settings = {};

    for (let index in this._mappedsettings) {
      let mappedItem = this._mappedsettings[index];

      settings[index] = this.settingService[mappedItem.Object][mappedItem.Property];
    }

    return JSON.stringify(settings);
  }


  //Graph
  private loadStatistics() {

    this._chartdata = [];
    this._chartlabels = this._statistics.labels;
    for (var i = 0; i < this._statistics.idList.length; i++) {
      let index = this._graphvisible.indexOf(this._statistics.idList[i]);
      if (index > -1) {
        this._chartdata.push({ data: this._statistics.data[i], label: this._statistics.series[i] });

        this._headers.forEach((header) => {
          if (header.id == this._statistics.idList[i]) {
            header.property.Graph = 2;
          }
        });
      }
    }

    //this._chartoptions = {
    //  responsive: true,
    //  // We use these empty structures as placeholders for dynamic theming.
    //  scales: {
    //    xAxes: [{
    //      stacked: false
    //    }],
    //    yAxes: [{
    //      stacked: false,
    //      ticks: {
    //        beginAtZero: true
    //      }
    //    }]
    //  },
    //  plugins: {
    //    datalabels: {
    //      anchor: 'end',
    //      align: 'end',
    //    }
    //  }
    //};
  }



  //Helpers
  private mappedSettings() {

    //Manage filter settings for current report

    /*Shifts*/
    this._mappedsettings['intervaltype'] = { Object: 'booking', Property: 'intervaltype' };
    this._mappedsettings['weekdays'] = { Object: 'booking', Property: 'weekdays' };
    this._mappedsettings['bookingstatus'] = { Object: 'booking', Property: 'status' };
    this._mappedsettings['bookingpersonal'] = { Object: 'booking', Property: 'personal' };
    this._mappedsettings['shifttype'] = { Object: 'booking', Property: 'shifttype' };
    this._mappedsettings['reservationtype'] = { Object: 'booking', Property: 'reservation' };
    this._mappedsettings['replaceableasnoslot'] = { Object: 'booking', Property: 'replaceableasnoslot' };
    this._mappedsettings['bookingcheckin'] = { Object: 'booking', Property: 'checkin' };
    this._mappedsettings['bookingusertype'] = { Object: 'booking', Property: 'bookingtype' };
    this._mappedsettings['ownerId'] = { Object: 'booking', Property: 'owner' };
    this._mappedsettings['activityoption'] = { Object: 'booking', Property: 'activityoption' };
    this._mappedsettings['activitytypes'] = { Object: 'booking', Property: 'activitytypes' };
    this._mappedsettings['timetypes'] = { Object: 'booking', Property: 'timetypes' };
    this._mappedsettings['filterbookinglevelgroups'] = { Object: 'booking', Property: 'levelgroups' };
    this._mappedsettings['filterbookinglevels'] = { Object: 'booking', Property: 'levels' };
    /*Availability*/
    this._mappedsettings['usermarkeddateavailable'] = { Object: 'user', Property: 'availability' };
    this._mappedsettings['usermarkeddateavailabletypes'] = { Object: 'user', Property: 'availabilitylist' };
    this._mappedsettings['bookingtype'] = { Object: 'user', Property: 'bookingtype' };
    //Users
    this._mappedsettings['usersearch'] = { Object: 'user', Property: 'search' };
    this._mappedsettings['filterusers'] = { Object: 'user', Property: 'users' };
    this._mappedsettings['roles'] = { Object: 'user', Property: 'roles' };
    this._mappedsettings['activity'] = { Object: 'user', Property: 'activity' };
    this._mappedsettings['employment'] = { Object: 'user', Property: 'employment' };
    this._mappedsettings['employmentcategories'] = { Object: 'user', Property: 'employmentcategories'}
    this._mappedsettings['userframeworkcontracts'] = { Object: 'user', Property: 'frameworkcontracts' }
    this._mappedsettings['usercontract'] = { Object: 'user', Property: 'contract' }
    this._mappedsettings['usercontracts'] = { Object: 'user', Property: 'contracts' }
    //this._mappedsettings['nobelonging'] = { Object: 'user', Property: 'nobelonging' };
    this._mappedsettings['filteruserlevelgroups'] = { Object: 'user', Property: 'levelgroups' };
    this._mappedsettings['filteruserlevels'] = { Object: 'user', Property: 'levels' };
    /*Properties*/
    this._mappedsettings['useor'] = { Object: 'property', Property: 'useor' };
    this._mappedsettings['propertyfilter'] = { Object: 'property', Property: 'properties' };
      
  }
}
