<swe-card header="{{languageService.getItem(29)}}" *ngIf="booking" (refreshChange)="load()" [hasEditMode]="id>0&&booking.SaveAccess>1" [hasSave]="!saving&&booking.SaveAccess>1" (saveChange)="saveWithSerieConfirm()" [focus]="true" [rulelist]="['SweBookingProfile']" icon="bi-list-ul">
  <span swe-hits>
    {{booking.ShiftLength}}
  </span>
  <span swe-rightcommand>
    <i class="bi bi-eye-fill bi-swe-pull-right" *ngIf="booking.IsWatching" [attr.title]="languageService.getItem(339)"></i>
    <a class="swe-no-link" href="javascript:void(0);" (click)="send(3, $event)" [attr.title]="languageService.getItem(320)" *ngIf="booking&&booking.MessageAccess>0&&hasCell"><i class="bi bi-swe-fw bi-phone-fill bi-swe-pull-right"></i></a>
    <a class="swe-no-link" href="javascript:void(0);" (click)="send(1, $event)" [attr.title]="languageService.getItem(321)" *ngIf="booking&&booking.MessageAccess>0&&hasEmail"><i class="bi bi-swe-fw bi-envelope-fill bi-swe-pull-right"></i></a>
  </span>
  <swe-infotext [text]="languageService.getItem(532)" [preventdrowning]="true"></swe-infotext>
  <!--Level-->
  <div class="mb-{{editmode?3:0}} row" *ngIf="!loading&&!permissionService.permissions.HideLevels">
    <div class="swe-row-icon">
      <div class="swe-col-icon" [ngClass]="{'py-1': !editmode}">
        <i class="bi bi-swe-fw bi-swe bi-diagram-3-fill" [attr.title]="languageService.getItem(14)"></i>
      </div>
      <div class="swe-col-content">
        <swe-levelsearch [(model)]="booking.LevelId" (modelChange)="levelchosen($event)" [displayname]="booking.LevelName" [onlyone]="true" [type]="'Booking'" [disabled]="booking.Status==60||booking.LevelAccess<2" [autoload]="booking.Autoload" [reset]="levelreset" [markChanges]="true" [storeAtClient]="true" [loadOnClick]="true" *ngIf="editmode"></swe-levelsearch>
        <span class="small" *ngIf="booking.LevelPath&&booking.LevelPath.length>0"><i>({{booking.LevelPath}})</i>&nbsp;</span>
        <span *ngIf="!editmode">{{booking.LevelName}}</span>
      </div>
    </div>
  </div>
  <!--Status-->
  <swe-confirmserie (acceptChange)="seriesStatusSave($event)" isButton="false" (closeEvent)="seriesStatusClose()" isSerie="true" [isvisible]="showStatusModal" />
  <div class="mb-{{editmode?3:0}} row" *ngIf="!loading&&!permissionService.permissions.HideStatus">
    <div class="swe-row-icon">
      <div class="swe-col-icon" [ngClass]="{'py-1': !editmode}">
        <i class="bi bi-swe-fw bi-swe swe-icon-color" [ngClass]="{'bi-arrow-up-right-square': booking.Status==20,'bi-key-fill': booking.Status==30,'bi-clock-fill': booking.Status==40,'bi-check-lg': booking.Status==50,'bi-lock-fill': booking.Status==60}" [attr.title]="languageService.getItem(15)" *ngIf="booking.Status>10"></i>
        <i class="bi bi-swe-fw bi-swe bi-square swe-icon-color" [attr.title]="languageService.getItem(15)" *ngIf="booking.Status==10"></i>
      </div>
      <div class="swe-col-content">
        <!--Can't use swe-element cause of the disabled structure-->
        <select [attr.aria-label]="languageService.getItem(15)" class="form-control form-select" [(ngModel)]="booking.Status" (ngModelChange)="manageCoreChange('Status', $event)" [ngClass]="{'bg-warning': coreChanged.Status.Changed}" *ngIf="editmode">
          <option *ngFor="let statusObj of generalService.bookingstatus" [value]="statusObj.Id" [disabled]="(booking.Status<40 && statusObj.Id>30) || (booking.StatusAccess<3 && statusObj.Id<booking.Status) || (booking.StatusAccess<2 && statusObj.Id>booking.Status)">{{statusObj.Name}}</option>
        </select>
        <span style="vertical-align:sub;" *ngIf="!editmode">{{booking.StatusName}}</span>
      </div>
    </div>
  </div>
  <!--Header-->
  <div class="row" *ngIf="!loading&&booking.HeaderAccess>0">
    <div class="swe-row-icon" [ngClass]="{'swe-invalid-profile border-danger text-danger': !validation.header}">
      <div class="swe-col-icon" [ngClass]="{'py-1': !editmode}">
        <i class="bi bi-swe-fw bi-swe bi-quote bi-swe-rotate-180" [attr.title]="header.Name"></i>
      </div>
      <div class="swe-col-content">
        <swe-element [(model)]="booking.Header" [access]="editmode?3:1" [label]="header.Name" [propertyId]="booking.HeaderProperty" [placeholder]="header.Name" [disabled]="booking.HeaderAccess<2"></swe-element>
      </div>
    </div>
  </div>
  <!--DateTimes-->
  <div class="mb-{{editmode?3:0}} row">
    <div class="swe-row-icon" [ngClass]="{'swe-invalid-profile border-danger text-danger': !validation.datetime}">
      <div class="swe-col-icon" [ngClass]="{'py-1': !editmode}">
        <i class="bi bi-swe-fw bi-swe bi-clock-fill" [attr.title]="languageService.getItem(10)+' '+languageService.getItem(430)+' '+languageService.getItem(11)"></i>
      </div>
      <div class="swe-col-content">
        <swe-datetime [(start)]="booking.Start" [(end)]="booking.End" [labelStart]="languageService.getItem(10)" [labelEnd]="languageService.getItem(11)" [hideTime]="!permissionService.permissions.ShiftTime" [disabled]="booking.StartAccess<2" (startChange)="manageDateTime($event)" (endChange)="manageDateTime($event)" [markChanges]="true" [undoChanges]="isReload" [warninglength]="permissionService.permissions.ShiftWarningLength" *ngIf="editmode"></swe-datetime>
        <span style="vertical-align:sub;" *ngIf="!editmode">{{booking.DateTimeHeader}}</span>
      </div>
    </div>
  </div>
  <!--IncludedBreak-->
  <div class="row" *ngIf="!loading&&booking.BreakAccess>0&&permissionService.permissions.HasBreaks">
    <div class="swe-row-icon" [ngClass]="{'swe-invalid-profile border-danger text-danger': !validation.break}">
      <div class="swe-col-icon" [ngClass]="{'py-1': !editmode}">
        <i class="bi bi-swe-fw bi-swe bi-cup-hot-fill" [attr.title]="languageService.getItem(785)"></i>
      </div>
      <div class="swe-col-content">
        <swe-breaklist [(model)]="booking.Breaks" [readonly]="!editmode" [(mealbreak)]="booking.MealBreak" [mealbreakAccess]="permissionService.permissions.MealBreakAccess" [disabled]="booking.BreakAccess<2" [disabledStart]="booking.BreakStartAccess<2" [minStart]="booking.Start" [maxStart]="booking.End" [markChanges]="true"></swe-breaklist>
      </div>

    </div>
  </div>
    <div>
    </div>
  <!--TimeType-->
  <div class="row" *ngIf="!loading&&timetypes.length>0&&booking.TimeTypeAccess>0">
    <swe-adminrule [rulelist]="['SweBookingTimeTypes']"></swe-adminrule>
    <div class="swe-row-icon">
      <div class="swe-col-icon" [ngClass]="{'py-1': !editmode}">
        <i class="bi bi-swe-fw bi-swe bi-journal" [attr.title]="languageService.getItem(1121)"></i>
      </div>
      <div class="swe-col-content">
        <swe-element [(model)]="booking.TimeType" (modelChange)="relatedObjectChange()" [access]="editmode?3:1" [label]="languageService.getItem(1121)" [type]="'System.List'" [items]="timetypes" [disabled]="booking.TimeTypeAccess<2"></swe-element>
      </div>
    </div>
  </div>
  <!--SlotAmountMax-->
  <div class="row" *ngIf="!loading&&booking.MaxAccess>0">
    <div class="swe-row-icon">
      <div class="swe-col-icon" [ngClass]="{'py-1': !editmode}">
        <i class="bi bi-swe-fw bi-swe bi-people-fill" [attr.title]="languageService.getItem(888)"></i>
      </div>
      <div class="swe-col-content">
        <swe-element [(model)]="booking.Max" [access]="editmode?3:1" [label]="max.Name" [propertyId]="booking.MaxProperty" [type]="'System.Int32'" [style]="'width:60px'" [disabled]="booking.MaxAccess<2"></swe-element>
      </div>
    </div>
  </div>
  <!--Owner-->
  <div class="mb-{{editmode?3:0}} row" *ngIf="!loading&&permissionService.permissions.Owner>0">
    <swe-adminrule [rulelist]="['SweOwner']"></swe-adminrule>
    <div class="swe-row-icon">
      <div class="swe-col-icon" [ngClass]="{'py-1': !editmode}">
        <i class="bi bi-swe-fw bi-swe bi-key-fill" [attr.title]="languageService.getItem(654)"></i>
      </div>
      <div class="swe-col-content">
        <swe-usersearch [(model)]="booking.UserId" [displayname]="booking.User" [onlyone]="true" [type]="'Owner'" [disabled]="booking.Status==60||permissionService.permissions.Owner<2" [markChanges]="true" *ngIf="editmode"></swe-usersearch>
        <span style="vertical-align:sub;" *ngIf="!editmode">{{booking.User}}</span>
      </div>
    </div>
  </div>
  <!--AutoBook-->
  <div class="row" *ngIf="!loading&&booking.AutoAccess>0">
    <swe-adminrule [rulelist]="['SweBookingAutoFlag']"></swe-adminrule>
    <div class="swe-row-icon">
      <div class="swe-col-icon" [ngClass]="{'py-1': !editmode}">
        <i class="bi bi-swe-fw bi-swe bi-magic" [attr.title]="languageService.getItem(797)"></i>
      </div>
      <div class="swe-col-content">
        <swe-element [(model)]="booking.Auto" [access]="editmode?3:1" [label]="languageService.getItem(797)" [type]="'System.Boolean'" [container]="{markChanges: true}"></swe-element>
      </div>
    </div>
  </div>
  <!--Series-->
  <div *ngIf="!loading&&booking.SeriesAccess>0||booking.Serie.Id>0">
    <swe-card [header]="languageService.getItem(529)" [color]="'swe'" [open]="false" [hasRefresh]="false" (openChange)="loadSeries()" [rulelist]="['SweBookingSeries']">
      <span swe-leftcommand><i class="bi bi-link-45deg bi-swe-inverse bg-primary me-2" *ngIf="booking.Serie.Id>0"></i></span>
      <swe-infotext [text]="languageService.getItem(534)" [preventdrowning]="true"></swe-infotext>
      <swe-element [(model)]="booking.Serie.Type" (modelChange)="changeSerieType($event)" [statusLabel]="2" [label]="languageService.getItem(251)" [items]="serieTypeList" [type]="'System.List'" [disabled]="booking.Serie.Id>0"></swe-element>
      <swe-element [(model)]="serieOption" [label]="languageService.getItem(252)" [items]="serieOptionList" [type]="'System.RadioList'" [disabled]="!isSerie||booking.Serie.Id>0"></swe-element>
      <swe-element [(model)]="booking.Serie.Days" [container]="container" [statusLabel]="2" [label]="languageService.getItem(629)" [items]="serieDayList" [type]="'System.MultiList'" [disabled]="!isSerie||booking.Serie.Id>0"></swe-element>
      <div class="mb-3">
        <label>{{languageService.getItem(255)}}</label>
        <div class="form-check">
          <input type="radio" class="form-check-input swe-click" [(ngModel)]="endOption" name="endoptions" [value]="1" [attr.aria-label]="languageService.getItem(255)" [disabled]="!isSerie||booking.Serie.Id>0" />
          <swe-element [(model)]="booking.Serie.End" [label]="languageService.getItem(255)" [type]="'System.Date'" [disabled]="!isSerie||booking.Serie.Id>0||endOption==0"></swe-element>
        </div>
        <div class="form-check">
          <input type="radio" class="form-check-input swe-click" [(ngModel)]="endOption" name="endoptions" [value]="0" [attr.aria-label]="languageService.getItem(255)" [disabled]="!isSerie||booking.Serie.Id>0" />
          <input type="number" class="form-control w-25 d-inline" [(ngModel)]="booking.Serie.Times" [attr.aria-label]="languageService.getItem(697)" [disabled]="!isSerie||booking.Serie.Id>0||endOption==1" />
          <label class="form-check-label ms-2">{{languageService.getItem(697)}}</label>
        </div>
      </div>
      <div class="mb-3" *ngIf="booking.SeriesAccess>0&&booking.Serie.Id==0&&booking.Id>0">
        <swe-element [(model)]="includeUsers" [statusLabel]="1" [label]="languageService.getItem(357)" [disabled]="!isSerie" [type]="'System.Boolean'"></swe-element>
      </div>
      <div class="d-grid gap-2" *ngIf="booking.SeriesAccess>0&&booking.Serie.Id>0">
        <swe-confirm [body]="languageService.getItem(266)" (acceptChange)="deleteSerie()">
          <button class="btn btn-danger"><i class="bi bi-swe-fw bi-trash-fill"></i>&nbsp;{{languageService.getItem(267)}}</button>
        </swe-confirm>
      </div>
      <div class="list-group" *ngIf="booking.Serie.Id>0">
        <h2>{{languageService.getItem(299)}} (#{{booking.Serie.Id}})</h2>
        <a class="list-group-item list-group-item-action d-flex flex-wrap swe-click" *ngFor="let serie of series" [ngClass]="{'list-group-item-warning': booking.Id==serie.Id}" (click)="openSerie(serie.Id, $event)">
          <div class="col-12 col-md-5">{{serie.dateheader}}</div>
          <div class="col-12 col-md-2">{{serie.Amount}} ({{serie.Max}})</div>
          <div class="col-12 col-md-5">{{serie.Header}}</div>
        </a>
      </div>
    </swe-card>
  </div>
  <!--Activities-->
  <div *ngIf="!loading&&permissionService.permissions.Activity>0">
    <swe-card [header]="languageService.getItem(395)" [color]="'swe'" [open]="!booking.HasActivities" [hasRefresh]="false" (openChange)="openActivities($event)" [rulelist]="['SweHasActivities']">
      <span swe-leftcommand><i class="bi bi-list-ul text-primary me-2" *ngIf="booking.HasActivities"></i></span>
      <span swe-rightcommand><i class="bi bi-plus-lg bi-swe-pull-right swe-click" *ngIf="permissionService.permissions.Activity>1&&activities&&activities.length>0&&!activities[0].isedit" (click)="editActivity(activities[0], $event)"></i></span>
      <div class="list-group">
        <ng-container *ngFor="let activity of activities">
          <div class="list-group-item list-group-item-action d-flex flex-wrap swe-click" (click)="editActivity(activity, $event)" *ngIf="permissionService.permissions.Activity>0 && !activity.isedit && activity.Id!=0">
            <div class="col-4 col-md-4">{{activity.dateheader}}</div>
            <div class="col-8 col-md-4"><div style="display:inline-block;width:10px;height:10px;border:1px solid #999999;" [ngStyle]="{'background-color': activity.Color}"></div>&nbsp;{{activity.Type}}</div>
            <div class="col-12 col-md-3">{{activity.Text}}</div>
            <div class="col-12 col-md-1"><i class="bi bi-swe-fw bi-swe bi-trash-fill bi-swe-pull-right swe-click" (click)="deleteActivity(activity,$event)" *ngIf="permissionService.permissions.Activity>1"></i></div>
          </div>
          <swe-card [header]="languageService.getItem(395)" [color]="'swe'" [open]="true" [hasRefresh]="false" [hasCollapse]="false" *ngIf="activity.isedit">
            <span swe-rightcommand><i class="bi bi-x-lg bi-swe-pull-right swe-click" (click)="editActivity(activity, $event)"></i></span>
            <swe-datetime [(start)]="activity.Start" [(end)]="activity.End" [labelStart]="languageService.getItem(396)" [adjustEnd]="false" [labelEnd]="languageService.getItem(397)" [hideTime]="!permissionService.permissions.ShiftTime"></swe-datetime>
            <swe-element [(model)]="activity.TypeId" [statusLabel]="2" [label]="languageService.getItem(398)" [items]="generalService.activitytypes" [type]="'System.ColorList'"></swe-element>
            <swe-element [(model)]="activity.Text" [statusLabel]="2" [label]="languageService.getItem(399)" [type]="'System.String'"></swe-element>
            <div class="d-grid gap-2">
              <button class="btn btn-primary" (click)="saveActivity(activity, $event)"><i class="bi bi-swe-fw bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
              <button class="btn btn-danger" (click)="deleteActivity(activity, $event)"><i class="bi bi-swe-fw bi-trash-fill"></i>&nbsp;{{languageService.getItem(17)}}</button>
            </div>
          </swe-card>
        </ng-container>
      </div>
    </swe-card>
  </div>
  <!--Split shift-->
  <div *ngIf="!loading&&booking.SplitAccess>0&&booking.Id>0&&permissionService.permissions.ShiftTime">
    <swe-card [header]="languageService.getItem(1029)" [color]="'swe'" [open]="false" [rulelist]="['SweBookingSplit']">
      <swe-element [type]="'System.DateTime'" [(model)]="splittimeFirst" [statusLabel]="0" [label]="languageService.getItem(1029)"></swe-element>
      <swe-element [type]="'System.DateTime'" [(model)]="splittimeSecond" [statusLabel]="0" [label]="languageService.getItem(1029)"></swe-element>
      <div class="d-grid gap-2">
        <button class="btn btn-primary" (click)="splitShift()"><i class="bi bi-swe-fw bi-layout-split"></i>&nbsp;{{languageService.getItem(1030)}}</button>
      </div>
    </swe-card>
  </div>
  <!--EmploymentPlan-->
  <div *ngIf="!loading&&permissionService.permissions.EmploymentPlan>0&&!permissionService.permissions.HideShifts" class="row">
    <swe-adminrule [rulelist]="['SweEmploymentPlan']"></swe-adminrule>
    <swe-element [(model)]="booking.EmploymentPlan" [access]="editmode?3:1" [label]="languageService.getItem(698)" [statusLabel]="1" [type]="'System.Boolean'"></swe-element>
  </div>
  <!--Profile-->
  <div *ngIf="!loading">
    <swe-profile [(model)]="booking.Profile" (modelChange)="profiledone()" [id]="id" [hasEditMode]="true" [editMode]="editmode" [relatedObjectList]="relatedObjectList"></swe-profile>
  </div>
  <!--Remove ATK-->
  <div *ngIf="!loading&&permissionService.permissions.RemoveATK" class="row">
    <swe-adminrule [rulelist]="['SweRemoveATK']"></swe-adminrule>
    <swe-element [(model)]="booking.RemoveATK" [access]="editmode?3:1" [statusLabel]="1" [label]="languageService.getItem(943)" [type]="'System.Boolean'"></swe-element>
  </div>
  <!--UserEmploymentCategories-->
  <div *ngIf="!loading&&booking&&booking.UserEmploymentAccess>0" class="row">
    <swe-adminrule [rulelist]="['SweShiftUserEmploymentCategory']"></swe-adminrule>
    <swe-element [type]="'System.List'" [label]="languageService.getItem(1065)" [statusLabel]="1" [(model)]="booking.EmploymentCategoryId" [access]="editmode&&booking.UserEmploymentAccess > 1?3:1" [items]="employmentcategories" [optional]="true"></swe-element>
  </div>
  <!--BookingTemplate-->
  <div *ngIf="!loading&&booking&&booking.TemplateId>0&&permissionService.permissions.Templates>0" class="row">
    <swe-element [type]="'System.InternalLinkList'" [label]="languageService.getItem(1400)" [statusLabel]="1" [(model)]="booking.TemplateId" [container]="linkcontainer()" [access]="1"></swe-element>
  </div>
  <!--Hidden User-->
  <div class="my-2" *ngIf="!loading&&userid>0">
    <div class="btn-group">
      <span class="swe-click" (click)="checkuser=!checkuser">
        <i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !checkuser, 'bi-check-square': checkuser}"></i>{{languageService.getItem(281)}} {{user}}
      </span>
    </div>
    <div class="btn-group ms-2">
      <!--Email Notification-->
      <button [attr.aria-label]="languageService.getItem(920)" [attr.aria-title]="languageService.getItem(921)" class="btn btn-swe" (click)="checknotificationemail=!checknotificationemail" *ngIf="permissionService.permissions.NotifyOnBookEmail>0">
        <i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !checknotificationemail, 'bi-check-square': checknotificationemail}"></i>
        <i class="bi bi-swe-fw bi-swe bi-envelope-fill"></i>
      </button>
      <!--SMS Notification-->
      <button [attr.aria-label]="languageService.getItem(921)" [attr.aria-title]="languageService.getItem(921)" class="btn btn-swe" (click)="checknotificationsms=!checknotificationsms" *ngIf="permissionService.permissions.NotifyOnBookSms>0">
        <i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !checknotificationsms, 'bi-check-square': checknotificationsms}"></i>
        <i class="bi bi-swe-fw bi-swe bi-phone-fill"></i>
      </button>
    </div>
  </div>
  <div class="d-grid gap-2" *ngIf="!loading&&!saving">
    <!--Buttons-->
    <!--Save-->
    <swe-adminrule [rulelist]="['SweBookingLevels']" *ngIf="booking.SaveAccess>1"></swe-adminrule>
    <swe-confirmserie (acceptChange)="save($event)" [isSerie]="booking.Serie&&booking.Serie.Id>0" [affectAllOnly]="booking.AffectAllOnly" *ngIf="booking.SaveAccess>1&&editmode">
      <button class="btn btn-primary" #sweSaveElement><i class="bi bi-swe-fw bi-floppy2-fill"></i>&nbsp;{{languageService.getItem(16)}}</button>
    </swe-confirmserie>
    <!--Copy-->
    <swe-adminrule [rulelist]="['SweBookingSaveCopy']" *ngIf="booking.Id>0 && booking.CopyAccess>0"></swe-adminrule>
    <swe-confirm [body]="languageService.getItem(522)" (acceptChange)="copy()" *ngIf="booking.Id>0 && booking.CopyAccess>0">
      <button class="btn btn-primary"><i class="bi bi-swe-fw bi-journals"></i>&nbsp;{{languageService.getItem(477)}}</button>
    </swe-confirm>
    <!--Slots-->
    <swe-adminrule [rulelist]="['SweBookingSlots', 'SweBookMyself']" *ngIf="permissionService.permissions.Slots&&booking.MyselfAccess==2"></swe-adminrule>
    <swe-element [type]="'System.Int32'" [label]="languageService.getItem(884)" [(model)]="slots" *ngIf="permissionService.permissions.Slots&&booking.MyselfAccess==2"></swe-element>
    <!--Book MySelf-->
    <swe-adminrule [rulelist]="['SweBookMyself']" *ngIf="booking.MyselfStandbyAccess >= 2"></swe-adminrule>
    <swe-confirmserie [isSerie]="booking.Serie&&booking.Serie.Id>0" (acceptChange)="bookMyselfPopUp($event, 1)" [affectAllOnly]="booking.AffectAllOnly" *ngIf="booking.MyselfAccess==2">
      <button class="btn btn-success"><i class="bi bi-calendar-plus"></i>&nbsp;{{languageService.getItem(248)}}</button>
    </swe-confirmserie>
    <swe-confirmserie [isSerie]="booking.Serie&&booking.Serie.Id>0" (acceptChange)="unbookMyself($event, 1)" [affectAllOnly]="booking.AffectAllOnly" *ngIf="booking.MyselfAccess==3">
      <button class="btn btn-success"><i class="bi bi-calendar-minus"></i>&nbsp;{{languageService.getItem(249)}}</button>
    </swe-confirmserie>
    <!--Book MySelf As Standby-->
    <swe-adminrule [rulelist]="['SweBookMyselfStandby']" *ngIf="booking.MyselfStandbyAccess >= 2"></swe-adminrule>
    <swe-confirmserie [isSerie]="booking.Serie&&booking.Serie.Id>0" (acceptChange)="bookMyself($event, 2)" [affectAllOnly]="booking.AffectAllOnly" *ngIf="booking.MyselfStandbyAccess==2">
      <button class="btn btn-success"><i class="bi bi-calendar-plus"></i>&nbsp;{{languageService.getItem(690)}}</button>
    </swe-confirmserie>
    <swe-confirmserie [isSerie]="booking.Serie&&booking.Serie.Id>0" (acceptChange)="unbookMyself($event, 2)" [affectAllOnly]="booking.AffectAllOnly" *ngIf="booking.MyselfStandbyAccess==3">
      <button class="btn btn-success"><i class="bi bi-calendar-minus"></i>&nbsp;{{languageService.getItem(691)}}</button>
    </swe-confirmserie>
    <!--Book MySelf As Abscent-->
    <swe-adminrule [rulelist]="['SweBookMyselfAbscent']" *ngIf="booking.MyselfAbscentAccess >= 2"></swe-adminrule>
    <swe-confirmserie [isSerie]="booking.Serie&&booking.Serie.Id>0" (acceptChange)="bookMyselfPopUp($event, 3)" [affectAllOnly]="booking.AffectAllOnly" *ngIf="booking.MyselfAbscentAccess==2">
      <button class="btn btn-success"><i class="bi bi-calendar-plus"></i>&nbsp;{{languageService.getItem(941)}}</button>
    </swe-confirmserie>
    <swe-confirmserie [isSerie]="booking.Serie&&booking.Serie.Id>0" (acceptChange)="unbookMyself($event, 3)" [affectAllOnly]="booking.AffectAllOnly" *ngIf="booking.MyselfAbscentAccess==3">
      <button class="btn btn-success"><i class="bi bi-calendar-minus"></i>&nbsp;{{languageService.getItem(942)}}</button>
    </swe-confirmserie>
    <!--Book MySelf As WantToChange-->
    <swe-adminrule [rulelist]="['SweBookMyselfAbscent']" *ngIf="booking.ReplaceableAccess>0"></swe-adminrule>
    <swe-confirmserie [isSerie]="booking.Serie&&booking.Serie.Id>0" (acceptChange)="bookMyself($event, 0)" [affectAllOnly]="booking.AffectAllOnly" *ngIf="booking.ReplaceableAccess>0">
      <button class="btn btn-success"><i class="bi bi-calendar-plus"></i>&nbsp;{{languageService.getItem(426)}}</button>
    </swe-confirmserie>
    <!--Delete-->
    <swe-adminrule [rulelist]="['SweBookingLevels']" *ngIf="booking.Id>0 && booking.SaveAccess>2"></swe-adminrule>
    <swe-confirmserie (acceptChange)="delete($event)" [isSerie]="booking.Serie&&booking.Serie.Id>0" [affectAllOnly]="booking.AffectAllOnly" *ngIf="booking.Serie&&booking.Serie.Id>0&&booking.Id>0&&booking.SaveAccess>2">
      <button class="btn btn-danger"><i class="bi bi-swe-fw bi-trash-fill"></i>&nbsp;{{languageService.getItem(17)}}</button>
    </swe-confirmserie>
    <swe-confirm [body]="deleteConfirm()" (acceptChange)="delete()" *ngIf="!(booking.Serie&&booking.Serie.Id>0)&&booking.Id>0 && booking.SaveAccess>2">
      <button class="btn btn-danger"><i class="bi bi-swe-fw bi-trash-fill"></i>&nbsp;{{languageService.getItem(17)}}</button>
    </swe-confirm>
  </div>
</swe-card>

<!--Remind SMS when Book yourself-->
<swe-popup [header]="languageService.getItem(538)" [doneText]="languageService.getItem(248)" [(visible)]="popup[0].Visible" [event]="popup[0].Event" (doneChange)="bookMyself(popup[0].Option, $event)">
  <div swe-popup-body class="btn-group ms-2">
    <button [attr.aria-label]="languageService.getItem(538)" [attr.title]="languageService.getItem(538)" class="btn btn-swe" (click)="checkremind=!checkremind">
      <i class="bi bi-swe-fw bi-swe" [ngClass]="{'bi-square': !checkremind, 'bi-check-square': checkremind}"></i>
      <i class="bi bi-swe-fw bi-swe bi-chat-fill"></i>
    </button>
    <swe-element [type]="'System.DateTime'" [label]="languageService.getItem(538)" [(model)]="remind" [bottomMargin]="0"></swe-element>
  </div>
</swe-popup>

<!--Profile when Book yourself as Abscent-->
<swe-abscentdata [buttontext]="languageService.getItem(941)" [id]="booking.MySelfTimereport" [userid]="permissionService.user.Id" [bookingid]="booking.Id" [(visible)]="popup[1].Visible" [option]="popup[1].Option" (doneChange)="reloadAfterBook()" *ngIf="booking"></swe-abscentdata>
